/* You can add global styles to this file, and also import other style files */

//UI PANEL

.tippy-box[data-theme~='light'] {
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  padding: 5px;
}

body {
  background-color: #88949E;
}
ul {
  padding-left: 0 !important;
}

.p-multiselect .p-multiselect-label {
  margin-bottom: 0 !important;
}

.p-dialog {
  background-color: #f8f9fa;
}

.p-toggleable-content  {
    box-shadow: 4px 4px 2px -2px rgba(0, 0, 0, 0.45);
    background-color: #f8f9fa;
    background-image: url('assets/bg4.jpg') !important;
    border-radius: 20px;
    padding: 1.5em;
}

.p-panel-content {
  background-color: transparent;
}

.p-panel-header {
    background: transparent;
}


.p-panel-header-icon {
  margin-left: .2em;
  margin-top: -0.1em;
  border: none;
}

.p-panel-footer {
  border: none;
}

.p-dropdown {
  width: 100% !important;
  font-size: 0.9em;
}

.p-listbox {
  width: 100% !important;
}

.p-inputtext {
  width: 100% !important;
  font-size: 0.9em;
}

.p-widget input {
  width: 100% !important;
  font-size: 0.8em !important;
}

.p-calendar {
  width: 100% !important;
}

.p-panel-header {
  margin: 1px !important;
}

.p-autocomplete-dd input.p-corner-all, .p-autocomplete-dd .p-autocomplete-multiple-container.p-corner-all {
  width: auto !important;
}

.p-widget-header {
  background: transparent !important;
  border: none !important;
}

table .p-datatable-table{
  width: 100%;
}

.p-datatable .p-datatable-header {
  background: none;
  border: none;
}

// Color de la cabecera de las tablas
table .p-datatable-thead > tr > th, table .p-datatable-tfoot > tr > td {
  color: white !important;
  background-color: #0F6938 !important;
  border: none !important;
}

.p-sortable-column-icon { 
  color: white !important;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: transparent !important;
  color: white;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: black !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  border: none !important;
  font-size: 0.9em;
}

.p-datatable .p-sortable-column.p-highlight {
  background: none;
}

.p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background: #EDF9ED;
}

//Hover de las filas de la tabla
.p-datatable .p-datatable-tbody > tr:hover {
  background-color: #babec2;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: rgba(63, 81, 181, 0.12);
  color: aliceblue;
}


.p-datatable-tbody {
  font-size: 0.8em !important;
}

.p-paginator-bottom {
  background: none !important;
  border: none;
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
  color: #555;
  border: 1px solid #d6d6d6;
  background-color: #fff;
  border-radius: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  color: #555;
  border: 1px solid #d6d6d6;
  background-color: #fff;
  border-radius: 0;

}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #186ba0;
  color: #fff;
  border-color: #156090;
  border-radius: 0;

}

.fc-scrollgrid-sync-table {
  background-color: white;
  width: 100%;
}

.fc-col-header-cell fc-day{
  border: none;
}

.fc thead .fc td, .fc th {
  border: none;
}

.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc a {
  color: black;
  text-decoration: none;
}
/*

.ui-table {
  background: red;
}

table .ui-table-tbody > tr > td {
  border: none !important;
  border-bottom: 1px solid green !important;
}

.ui-table-scrollable-wrapper {
  border: none;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

.ui-table .ui-table-thead > tr > th, .ui-table .ui-table-tfoot > tr > td {
  background-color: rgba(53, 212, 111, 0.81);
}

.ui-table-caption {
  border-bottom: 0 none;
  background: rgba(53, 212, 111, 0.81) !important;
}

.ui-table .ui-table-thead > tr > th, .ui-table .ui-table-tfoot > tr > td {
  border: 1px solid green;
}*/

//SPINNER
@keyframes ui-progress-spinner-color {
  100%,
  0% {
    stroke: #0057e7;
  }
}

.iwinput{
  height: 24px !important;
  padding: 4px !important;
  line-height: 0 !important;
  margin-top: -4px;
  text-align: right;
}

.no-zindex {
  z-index: 0 !important;
}

// IS LOADING
#is-loading-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.canvas-wrap {
  border: 1px solid white !important;
  width: 300px;
  height: 150px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  .btn {
    position: absolute;
    font-size: .6rem;
  }
  &:hover {
    border: 1px solid gray !important;
  }
}

/*
* Cargos trabajdores table
*/

#cargos-table .p-datatable {
  max-width: 400px !important;
}

#cargos-table .p-datatable .p-datatable-tbody > tr > td {
  border: none !important;
  font-size: 17px;
}

/*
* End cargos trabajdores table
*/

/*
* Botones análisis
*/

.alinear_botones_analisis {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

/*
* Estilos campaña en tareas
*/
#campaign {
  margin-left: 10px;
  max-width: 70%; 
  display: flex; 
  justify-content: center;
  label{
    margin-right: 20px;
    position: relative;
    top: 3px;
    color: white;
  }
  p-dropdown{
    min-width: 150px !important;
    width: 150px !important;
    label{
      color:black !important;
    }
  }
}
#campaignAno {
  margin-left: 10px;
  max-width: 70%; 
  display: flex; 
  justify-content: center;
  label{
    margin-right: 20px;
    position: relative;
    top: 3px;
    color: white;
  }
  p-dropdown{
    min-width: 75px !important;
    width: 75px !important;
    label{
      color:black !important;
    }
  }
}
/*
* Fin de estilos campaña en Tareas
*/ 
/*
* En el modal info transacciones
* Cambiar la posición del inputswitch, 
* para que se alineé con los otros campos
*/
#transaccion-info p-inputswitch{
  position: relative !important;
  bottom: 20px !important;
}

/*
* Fin del modal info transacciones
* 
*/

/*
* Button chart Manez y Lozano
*/

.button-display-charts{
  height: 30px;
  width: 40px;
  padding: 4px;
  border-radius: 5px;
  background-color: grey;
  color: white;
  cursor: pointer;
}

.button-display-charts-focus{
  height: 30px;
  width: 40px;
  padding: 4px;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 5px;
  background-color: rgb(190, 190, 190);
  color: white;
  cursor: pointer;
}


.map-active {
  width:100%; height: 300px;
}

.map-inactive {
  width:0; height: 0;
}
/*
* End button chart
*/

/*
* Estilos box panel vademecum
*/

#vademecum-panel .p-multiselect .p-multiselect-panel {
  /* min-width: 100%; */
  max-width: 50%;
}

/*
* Fin de estilos box panel vademecum
*/

/*
 Calendario control 
*/

#control-calendar .fc-day-grid-event .fc-content {
 cursor: pointer;
}

.disable-button-chart{
  background-color: #70afd8 !important;
}

/* Tratamientos Mañez y Lozano dialog*/
#id-modal-tratamientos .p-dialog{
  top: 0 !important;
  left: 0 !important;
  min-width: 100vw !important;
  min-height: 100vh !important;
}

#photo-camera .p-dialog{
  min-width: 100vw !important;
  min-height: 100vh !important;
}


.tareas-camara{
  position: relative;
  top: 50px;
}

@media (max-width: 972px){
  .tareas-camara{
    top: 0;
    position: inherit;
    display: flex;
    justify-content: center;
  }
}


.info-firma-img{
   box-shadow: -1px 0px 4px 1px;
    border-radius: 10px;
    background: #f4efef;
    width: 400px;
    height: 200px;
    object-fit: cover;
    border: 1px solid grey;
}
//Dialogo de información en la ruta tractores (calendario)
#hook{
  -webkit-box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: inset 0px 0px 20px 0px rgba(0,0,0,0.75);
  box-shadow: inset 0px 0px 20px 0px rgba(0,0,0,0.75);
  background-color: rgb(75, 75, 75);
  color: white;
  padding: 2em;
  
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 5px ;
 
}
#hook p{
  font-size: 1.1em;
}
#hook p strong{
  font-size: 1.1em;
  font-weight: 600;
}
.gm-style-iw .gm-style-iw-c::after{
  background-color: rgb(75, 75, 75) !important;
  padding: 0%;
  

}
.gm-style-iw-d{
  overflow: hidden !important;
}

.gm-style .gm-style-iw-c{
  padding-top: 0px;
  padding-left: 0px;
}

#id-modal-tratamientos p-dialog div div div.ng-tns-c4042076560-14.p-dialog-content div div div div.resp-iframe.map-active.ng-star-inserted div div.gm-style div:nth-child(1) div:nth-child(2) div div:nth-child(4) div div div div.gm-style-iw.gm-style-iw-c button {
  background-color: white !important; 
  border-radius: 200px !important; 
  margin: 8px !important;
}

//Ancho Sectores y parcelas multiselect
.p-multiselect {
  width: 100% !important;
}